.card {
  border: 1px solid black;
}


#bigCard {
  height: 18cm;
  width: 10cm;
  margin: 0px auto;
}

p, pre {
  padding: 0;
  margin: 0;
}

#bigCardName {
  display: flex;
  max-height: 8cm;
  min-height: 8cm;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#bigCardName pre {
  display: block;
  max-width: 10cm;
  max-height: 8cm;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

#bigCardAlergens {
  background-color: rgb(226, 224, 224);
  border-top: 1px solid black;
  min-height: 2cm;
  max-height: 2cm;
}

#bigCardPrice {
  display: flex;
  min-height: 6cm;
  max-height: 6cm;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#bigCardPrice p {
  display: inline-block;
}

#bigSparator {
  min-height: 2cm;
  max-height: 2cm;
}


#smallCard {
  max-height: 14cm;
  min-height: 14cm;
  width: 7.5cm;
  margin: 0px auto;
}

#smallCardName {
  display: flex;
  max-height: 6cm;
  min-height: 6cm;
  text-align: center;
  justify-content: center;
  align-items: center;
}

#smallCardName pre {
  display: block;
  max-width: 7.5cm;
  max-height: 6cm;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}

#smallCardName p {
  display: inline-block;
}

#smallCardAlergens {
  background-color: rgb(226, 224, 224);
  border-top: 1px solid black;
  min-height: 1.5cm;
  max-height: 1.5cm;
}

#smallCardPrice {
  min-height: 5cm;
  max-height: 5cm;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#smallCardPrice p {
  display: inline-block;
}

#smallSeparator {
  min-height: 1.5cm;
  max-height: 1.5cm;
}

.bigNoAlergens {
  text-align: center;
  font-size: 36px;
  margin-top: 15px;
}

.smallNoAlergens {
  text-align: center;
  font-size: 26px;
  margin-top: 12px;
}

