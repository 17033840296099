#signEditor {
  width: 30%;
}

#signEditor h2 {
  margin: 0;
  margin-bottom: 15px;
}

.formTitle {
  text-align: center;
  border-bottom: #ccc 1px dashed;
}

.mt {
  margin-top: 25px !important;
}

.formGroup {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}

.input {
  height: 30px;
  font-size: 20px;
}

.multipleInputBox {
  flex-wrap: wrap;
  display: flex;
}

.multipleInput {
  width: 50%;
}

.formButtonGroup {
  display: flex;
  justify-content: space-around;
}

textarea {
  min-height: 75px;
}

.radioButtons {
  display: flex;
  justify-content: space-around;
}
