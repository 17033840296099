#alergen-list {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}

.smallAlergen {
  display: block;
  height: 1.5cm;
}
.bigAlergen {
    display: block;
  height: 2cm;
}
